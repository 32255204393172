<template>
  <content-loader
    :height="126"
    :width="866"
    :speed="3"
    :primary-color="primaryColor"
    :secondary-color="secondaryColor"
  >
    <rect x="20" y="49" rx="0" ry="0" width="26" height="26" />
    <rect x="67" y="44" rx="0" ry="0" width="190" height="10" />
    <rect x="67" y="67" rx="0" ry="0" width="110" height="5" />
    <rect x="187" y="67" rx="0" ry="0" width="50" height="5" />
    <rect x="127" y="79" rx="0" ry="0" width="110" height="5" />
    <rect x="67" y="79" rx="0" ry="0" width="50" height="5" />
    <circle cx="360" cy="63.6" r="25" />
    <circle cx="500" cy="63.6" r="25" />
    <circle cx="674" cy="63.6" r="25" />
    <rect x="790" y="39" rx="0" ry="0" width="37" height="9" />
  </content-loader>
</template>
<script>
import { ContentLoader } from 'vue-content-loader'
import Placeholder from './Placeholder'

export default {
  components: {
    ContentLoader
  },
  extends: Placeholder
}
</script>
